import useTranslation from 'next-translate/useTranslation';

import type { ChannelCategoryType } from 'types/channel';
import type { DisclosureType } from 'types/matchData';
import type { SportType } from 'types/scoreboardData';

const useTranslationList = () => {
  const { t } = useTranslation('scoreboard');
  const { t: event_t } = useTranslation('event');
  const { t: settings_t } = useTranslation('settings');

  const getPositionMenuList = (type: string) => {
    switch (type) {
      case 'baseball':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'P', content: t('positionObj.baseball.P') },
          { value: 'C', content: t('positionObj.baseball.C') },
          { value: '1B', content: t('positionObj.baseball.1B') },
          { value: '2B', content: t('positionObj.baseball.2B') },
          { value: '3B', content: t('positionObj.baseball.3B') },
          { value: 'SS', content: t('positionObj.baseball.SS') },
          { value: 'LF', content: t('positionObj.baseball.LF') },
          { value: 'CF', content: t('positionObj.baseball.CF') },
          { value: 'RF', content: t('positionObj.baseball.RF') },
          { value: 'DH', content: t('positionObj.baseball.DH') },
        ];
      case 'basketball':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'PG', content: t('positionObj.basketball.PG') },
          { value: 'SG', content: t('positionObj.basketball.SG') },
          { value: 'PF', content: t('positionObj.basketball.PF') },
          { value: 'SF', content: t('positionObj.basketball.SF') },
          { value: 'C', content: t('positionObj.basketball.C') },
        ];

      case 'cricket':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'WK', content: t('positionObj.cricket.WK') },
        ];

      case 'futsal':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'GO', content: t('positionObj.futsal.GO') },
          { value: 'PI', content: t('positionObj.futsal.PI') },
          { value: 'AL', content: t('positionObj.futsal.AL') },
          { value: 'FI', content: t('positionObj.futsal.FI') },
        ];

      case 'handball':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'GK', content: t('positionObj.handball.GK') },
          { value: 'CB', content: t('positionObj.handball.CB') },
          { value: 'RB', content: t('positionObj.handball.RB') },
          { value: 'LB', content: t('positionObj.handball.LB') },
          { value: 'RW', content: t('positionObj.handball.RW') },
          { value: 'LW', content: t('positionObj.handball.LW') },
          { value: 'PV', content: t('positionObj.handball.PV') },
        ];

      case 'rugby':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'prop', content: t('positionObj.rugby.prop') },
          { value: 'hooker', content: t('positionObj.rugby.hooker') },
          { value: 'secondRow', content: t('positionObj.rugby.secondRow') },
          { value: 'flanker', content: t('positionObj.rugby.flanker') },
          { value: 'number8', content: t('positionObj.rugby.number8') },
          { value: 'scrumHalf', content: t('positionObj.rugby.scrumHalf') },
          { value: 'flyHalf', content: t('positionObj.rugby.flyHalf') },
          {
            value: 'insideCenter',
            content: t('positionObj.rugby.insideCenter'),
          },
          {
            value: 'outsideCenter',
            content: t('positionObj.rugby.outsideCenter'),
          },
          { value: 'wing', content: t('positionObj.rugby.wing') },
          { value: 'fullback', content: t('positionObj.rugby.fullback') },
        ];

      case 'soccer':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'GK', content: t('positionObj.soccer.GK') },
          { value: 'DF', content: t('positionObj.soccer.DF') },
          { value: 'MF', content: t('positionObj.soccer.MF') },
          { value: 'FW', content: t('positionObj.soccer.FW') },
        ];

      case 'volleyball':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'OH', content: t('positionObj.volleyball.OH') },
          { value: 'OPP', content: t('positionObj.volleyball.OPP') },
          { value: 'C', content: t('positionObj.volleyball.C') },
          { value: 'S', content: t('positionObj.volleyball.S') },
          { value: 'Li', content: t('positionObj.volleyball.Li') },
        ];

      case 'iceHockey':
        return [
          { value: 'default', content: t('unselected') },
          { value: 'F', content: t('positionObj.iceHockey.F') },
          { value: 'D', content: t('positionObj.iceHockey.D') },
          { value: 'G', content: t('positionObj.iceHockey.G') },
        ];

      default:
        return [{ value: 'default', content: t('unselected') }];
    }
  };

  const getSortingOptionList = () => [
    { value: 'latest', content: t('sorts.latest') },
    { value: 'view', content: t('sorts.view') },
  ];

  const getDateRangeOptionList = () => [
    { value: 'all', content: t('dateRange.all') },
    { value: 'today', content: t('dateRange.today') },
    { value: 'pastWeek', content: t('dateRange.pastWeek') },
    { value: 'pastMonth', content: t('dateRange.pastMonth') },
    { value: 'pastYear', content: t('dateRange.pastYear') },
    { value: 'customRange', content: t('dateRange.customRange') },
  ];

  const getSportTypeList = (): { value: SportType; content: string }[] => [
    { value: 'universal', content: t('sportType.universal') },
    { value: 'baseball', content: t('sportType.baseball') },
    { value: 'basketball', content: t('sportType.basketball') },
    { value: 'soccer', content: t('sportType.soccer') },
    { value: 'pickleball', content: t('sportType.pickleball') },
    { value: 'taekwondo', content: t('sportType.taekwondo') },
    { value: 'tennis', content: t('sportType.tennis') },
    { value: 'futsal', content: t('sportType.futsal') },
    { value: 'tableTennis', content: t('sportType.tableTennis') },
    { value: 'volleyball', content: t('sportType.volleyball') },
    { value: 'badminton', content: t('sportType.badminton') },
    { value: 'hockey', content: t('sportType.hockey') },
    { value: 'iceHockey', content: t('sportType.iceHockey') },
    { value: 'cricket', content: t('sportType.cricket') },
    { value: 'billiard', content: t('sportType.billiard') },
    { value: 'handball', content: t('sportType.handball') },
    { value: 'jokgu', content: t('sportType.jokgu') },
    { value: 'golf', content: t('sportType.golf') },
    { value: 'squash', content: t('sportType.squash') },
    { value: 'football', content: t('sportType.football') },
    { value: 'sepakTakraw', content: t('sportType.sepakTakraw') },
    { value: 'athletics', content: t('sportType.athletics') },
    { value: 'esports', content: t('sportType.esports') },
    { value: 'bodybuilding', content: t('sportType.bodybuilding') },
    { value: 'climbing', content: t('sportType.climbing') },
    { value: 'rugby', content: t('sportType.rugby') },
  ];

  const getStatusList = () => [
    { value: 'schedule', content: t('statusList.schedule') },
    { value: 'live', content: t('statusList.live') },
    { value: 'end', content: t('statusList.end') },
  ];

  const getEventTypeList = () => [
    { value: 'universal', content: event_t('list.eventType.universal') },
    { value: 'sport', content: event_t('list.eventType.sport') },
    { value: 'seminar', content: event_t('list.eventType.seminar') },
  ];

  const getEventEnvironmentList = () => [
    { value: 'online', content: event_t('list.eventEnvironment.online') },
    { value: 'offline', content: event_t('list.eventEnvironment.offline') },
    {
      value: 'on/offline',
      content: event_t('list.eventEnvironment.on/offline'),
    },
  ];

  const getEventLeagueTypeList = () => [
    { value: 'child', content: event_t('leagueType.child') },
    {
      value: 'elementarySchool',
      content: event_t('leagueType.elementarySchool'),
    },
    { value: 'middleSchool', content: event_t('leagueType.middleSchool') },
    { value: 'highSchool', content: event_t('leagueType.highSchool') },
    { value: 'university', content: event_t('leagueType.university') },
    { value: 'club', content: event_t('leagueType.club') },
    { value: 'amateur', content: event_t('leagueType.amateur') },
    { value: 'pro', content: event_t('leagueType.pro') },
  ];

  const getLeagueTypeList = () => [
    { value: 'child', content: t('leagueType.child') },
    { value: 'elementarySchool', content: t('leagueType.elementarySchool') },
    { value: 'middleSchool', content: t('leagueType.middleSchool') },
    { value: 'highSchool', content: t('leagueType.highSchool') },
    { value: 'university', content: t('leagueType.university') },
    { value: 'club', content: t('leagueType.club') },
    { value: 'amateur', content: t('leagueType.amateur') },
    { value: 'pro', content: t('leagueType.pro') },
  ];

  const getDisclosureList = (): {
    value: DisclosureType;
    content: string;
  }[] => [
    { value: 'public', content: t('disclosure.public') },
    { value: 'unlisted', content: t('disclosure.unlisted') },
    { value: 'private', content: t('disclosure.private') },
  ];

  const getLogTypeList = () => [
    { value: 'TEAM_SCORE', content: t('Logs.score') },
    { value: 'TEAM_SET_SCORE', content: t('Logs.setScore') },
    { value: 'TEAM_FOUL', content: t('Logs.foul') },
    { value: 'CHANGE', content: t('Logs.change') },
    { value: 'PARTICIPATE', content: t('Logs.participate') },
  ];

  const getPlayerTypeList = () => [
    { value: 'main', content: t('main') },
    { value: 'sub', content: t('sub') },
  ];

  const getControlThemeList = (theme: string) => {
    let totalControllers;

    switch (theme) {
      case 'scoreboardHasSet':
      case 'baseball':
        totalControllers = 3;
        break;
      default:
        totalControllers = 2;
        break;
    }

    return new Array(totalControllers).fill(0).map((_, index) => ({
      value: `${index + 1}`,
      content: t(`ControllerTheme.controller${index + 1}`),
    }));
  };

  const getRoundList = (sportType: SportType) => {
    let roundCount = 0;
    switch (sportType) {
      case 'baseball':
        roundCount = 9;
        break;
      case 'badminton':
      case 'basketball':
      case 'jokgu':
      case 'sepakTakraw':
      case 'soccer':
      case 'squash':
      case 'tableTennis':
      case 'volleyball':
        roundCount = 5;
        break;
      case 'taekwondo':
        roundCount = 4;
        break;
      case 'pickleball':
        roundCount = 3;
        break;
      default:
        break;
    }

    return new Array(roundCount).fill(0).map((_, index) => ({
      value: `${index + 1}`,
      content:
        sportType === 'soccer' || sportType === 'taekwondo'
          ? t(`round.${sportType}.${index + 1}`)
          : t(`round.${sportType}`, { round: `${index + 1}` }),
    }));
  };

  const getLeaderboardThemeList = () => [
    { value: 'default', content: event_t('list.theme.default') },
    { value: 'round', content: event_t('list.theme.round') },
  ];

  const channelCategory = (text: string) =>
    settings_t(`detailSettingCard.categoryList.${text}`);
  const getChannelCategoryList = (): {
    value: ChannelCategoryType;
    content: string;
  }[] => [
    { value: 'sportsFacilities', content: channelCategory('sportsFacilities') },
    { value: 'creator', content: channelCategory('creator') },
    { value: 'teamGroup', content: channelCategory('teamGroup') },
    { value: 'businessCompany', content: channelCategory('businessCompany') },
    { value: 'institution', content: channelCategory('institution') },
    { value: 'clubs', content: channelCategory('clubs') },
    { value: 'player', content: channelCategory('player') },
    { value: 'association', content: channelCategory('association') },
    { value: 'community', content: channelCategory('community') },
    { value: 'common', content: channelCategory('common') },
  ];

  return {
    getChannelCategoryList,
    getControlThemeList,
    getDateRangeOptionList,
    getDisclosureList,
    getLeagueTypeList,
    getLogTypeList,
    getPlayerTypeList,
    getPositionMenuList,
    getSortingOptionList,
    getSportTypeList,
    getStatusList,
    getEventEnvironmentList,
    getEventLeagueTypeList,
    getEventTypeList,
    getLeaderboardThemeList,
    getRoundList,
  };
};

export default useTranslationList;
